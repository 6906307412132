//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgImage from '~/components/General/SvgImage.vue';
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import ProductQuantitySelector from "~/modules/catalog/product/components/ProductQuantitySelector.vue";

export default {
  name: "SfAddToCart",
  components: {
    SfButton,
    ProductQuantitySelector,
    SvgImage
  },
  model: {
    prop: "qty",
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    qty: {
      type: [Number, String],
      default: 1,
    },
    expectedQty: {
      type: Number,
      default: 0
    }
  },
};
